import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {StorageService} from "src/app/services/storage.service";
import {environment} from "../../../../environments/environment";
import {User} from "../classes/user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  readonly STORAGE_KEY = "tn";
  readonly STORAGE_ROLE = "rl";
  readonly STORAGE_ROLES = "rls";
  readonly STORAGE_CLUBS = "rc";
  readonly STORAGE_TPUSR = "tusr";

  redirectUrl: string;

  private token: string;
  private role: boolean;
  private roles: any[] | null;
  private clubs: any[] | null;
  private tpuser: any | null;

  constructor(private http: HttpClient, private storage: StorageService) {
  }

  getAuthorizationToken(): string | false {
    if (!this.token) {
      this.token = this.storage.get(this.STORAGE_KEY);
    }

    return this.token || false;
  }

  getAuthorizationRole(): boolean | false {
    if (!this.role) {
      this.role = (this.storage.get(this.STORAGE_ROLE)) === '1';
    }
    return this.role || false;
  }

  getAuthorizationRoles(): any[] | null {
    if (!this.roles) {
      this.roles = JSON.parse(this.storage.get(this.STORAGE_ROLES));
    }
    return this.roles;
  }
  isAdmin() : boolean {
    const _roles = this.getAuthorizationRoles();
    return _roles.indexOf('admin')>-1;
  }
  isManager() : boolean {
    const _roles = this.getAuthorizationRoles();
    return (_roles.indexOf('admin')>-1 || _roles.indexOf('team')>-1 || _roles.indexOf('club')>-1);
  }

  getTpUser(): any[] | null {
    if (!this.tpuser) {
      this.tpuser = JSON.parse(this.storage.get(this.STORAGE_TPUSR));
    }
    return this.tpuser;
  }

  getManagedClubs(): any[] | null {
    if (!this.clubs) {
      this.clubs = JSON.parse(this.storage.get(this.STORAGE_CLUBS));
    }
    return this.clubs;
  }

  isAuthorized(): boolean {
    return this.getAuthorizationToken() !== false;
  }

  login(username: string, password: string): Observable<User> {
    const loginData = {
      email: username,
      password: password,
    };

    return this.http
      .post<{
        admin: boolean;
        scopes: any[] | null;
        token: string;
        data: { user: User, clubs: any[] | null }
      }>(
        environment.apiUrl + "/login",
        loginData
      )
      .pipe(
        map((response) => {
          this.token = response.token;
          this.role = response.admin;
          this.roles = response.scopes;
          this.clubs = response.data.clubs;
          this.tpuser = Object.assign(new User(), response.data.user);
          if (this.roles.filter((u) => u === 'admin')) {
            this.role = true; //is Admin
          }
          this.storage.set(this.STORAGE_KEY, this.token);
          this.storage.set(this.STORAGE_ROLE, this.role ? '1' : '0');
          this.storage.set(this.STORAGE_ROLES, JSON.stringify(this.roles));
          this.storage.set(this.STORAGE_CLUBS, JSON.stringify(this.clubs));
          this.storage.set(this.STORAGE_TPUSR, JSON.stringify(this.tpuser));
          return Object.assign(new User(), response.data.user);
        }),
        catchError(this.handleError)
      );
  }

  logout() {
    this.token = null;
    this.storage.remove(this.STORAGE_KEY);
    this.storage.remove(this.STORAGE_ROLE);
    this.storage.remove(this.STORAGE_ROLES);
    this.storage.remove(this.STORAGE_CLUBS);
    this.storage.remove(this.STORAGE_TPUSR);
  }

  getUser(): Observable<User> {
    return this.http.get(environment.apiUrl + "/users").pipe(
      map((user) => Object.assign(new User(), user)),
      catchError(this.handleError)
    );
  }

  canDoIt(what: string, param: any = null): boolean {
    let ret = false
    const roles = this.getAuthorizationRoles()
    const tpUser = this.getTpUser()
    const teams = this.getManagedClubs()
    const isAdmin = this.isAdmin();
    const isClub = roles.indexOf('club')>-1;
    const isTeam = roles.indexOf('team')>-1;
    const isManager = this.isManager();

    /*
    console.log('canDoIt', {
      'what': what,
      param: param,
      tpUser: tpUser,
      roles: roles,
      teams: teams,
      isAdmin: isAdmin,
      isClub: isClub,
      isTeam: isTeam,
      isManager: isManager
    });
     */


    switch (what) {
      case 'addclub':
      case 'deleteclubuser':
      case 'addtracker':
      case 'edittracker':
      case 'removetracker':
        ret = isAdmin
        break;
      case 'addteam':
      case 'editteam':
      case 'editclub':
      case 'addclubuser':
      case 'editclubuser':
        ret = (isAdmin || isClub)
        break;
      case 'session':
      case 'club':
        ret = isManager
        break;
      case 'addplayer':
      case 'editplayer':
        ret = (isManager) && teams.indexOf(param) > -1
        break;
    }
    return ret
  }


  private handleError(error: HttpErrorResponse) {
    let errorMsg: string;
    if (error.error instanceof ErrorEvent) {
      errorMsg = $localize`:generic-error|:Si è verificato un errore: ${error.message}`;
    } else if (error.status == 401) {
      errorMsg = $localize`:auth-auth-error|Errore di autenticazione:Non ti sei autenticato. Nome utente e/o password errati`;
    } else if (error.status == 403) {
      errorMsg = $localize`:auth-access-forbidden|:Accesso negato`;
    } else {
      errorMsg = $localize`:generic-error-mes|:Si è verificato un errore sconosciuto.`;
    }

    return throwError(errorMsg);
  }
}
